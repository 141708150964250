<template>
  <div class="wrapper">
    <div class="head">
      <div class="head-title">你好，世界</div>
    </div>
    <div class="container">
      <div v-for="(item, key) in list" :key="key" class="item">
        <span @click="linkTo('Detail', item.id)">{{ item.title }}</span>
        <span>{{ item.date }} <i>/</i> {{ item.read }} min read</span>
      </div>
    </div>
    <div class="page">
      <div class="page-btn" @click="prePage()">上一页</div>
      <div class="page-btn" @click="nextPage()">下一页</div>
    </div>
    <a class="copyright" target="_blank" href="https://beian.miit.gov.cn/">{{ copyright }}</a>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from '@/utils/http'
import { Toast } from 'vant'
Vue.use(Toast)
export default {
  data() {
    return {
      name: '首页',
      icon: '',
      title: '首页',
      list: [],
      page: 1,
      page_size: 10,
      total_page: 1,
      copyright: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    linkTo (name, id = '') {
      if (id) {
        this.$router.push({ name: name, query: { id: id }})
      } else {
        this.$router.push({ name: name })
      }
    },
    // 获取首页广告列表
		getList () {
      let params = {
        page: this.page,
        page_size: this.page_size,
      }
			this.loading = true
      axios.get('http://adv.down5g.net/api/web/articles', {params: params}).then((res) => 
      {
        let code = res.code
        if (code != 1) {
          Toast('页面加载错误')
          return false
        } else {
          this.list = res.data.list
          this.total_page = res.data.page
          this.copyright = res.data.copyright
        }
      })
		},
    // 上一页
    prePage () {
      if (this.page <= 1) {
        Toast('没有上一页了')
        return
      }
      this.page = this.page > 1 ? this.page - 1 : 1
      this.getList()
    },
    // 下一页
    nextPage () {
      if (this.page + 1 > this.total_page) {
        Toast('没有下一页了')
        return
      }
      this.page = this.page + 1 > this.total_page ? this.page : this.page + 1 
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: block;
  width: 100%;
  background: #fff;
  // 头部
  .head {
    display: block;
    margin-bottom: 30px;
    background: url('~@/assets/images/bg.jpg') no-repeat center center;
    background-attachment: scroll;
    position: relative;
    background-size: cover;
    height: 330px;
    .head-title {
      display: flex;
      width: 100%;
      height: 360px;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 40px;
      font-weight: 800;
    }
  }
  .container {
    display: block;
    width: calc(100% - 40px);
    max-width: 750px;
    margin: 0 auto;
    padding: 0 20px;
    min-height: calc(100vh - 390px);
  }
  .item {
    display: flex;
    flex-flow: column;
    span:nth-child(1) {
      font-size: 28px;
      font-weight: 700;
      letter-spacing: 1px;
      padding: 24px 0;
      color: #333;
      cursor: pointer;
    }
    span:nth-child(2) {
      color: #5E5E5E;
      font-size: 12px;
      padding-bottom: 24px;
      i {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.1);
        margin: 0 4px;
      }
    }
  }
  .page {
    display: flex;
    width: calc(100% - 40px);
    max-width: 750px;
    margin: 0 auto;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    .page-btn {
      margin-left: 20px;
    }
  }
  .copyright {
    display: flex;
    width: calc(100% - 40px);
    max-width: 750px;
    margin: 0 auto;
    font-size: 14px;
    height: 60px;
    justify-content: center;
    align-items: center;
  }
}
</style>
